// simplyunified colors

// primary

$light-primary: #bd9cd8;
$light-primary-contrast: #ffffff;

$dark-primary: #bd9cd8;
$dark-primary-contrast: #ffffff;

// accent/secondary

$light-accent: #87bfe9;
$light-accent-contrast: #ffffff;

$dark-accent: #87bfe9;
$dark-accent-contrast: #ffffff;

// common

$page-background: #bd9cd82f;
